<template>
  <!-- 小迈简介 -->
  <section id="aboutCourse">
    <p class="w-1440 section-title pl-88 pt-80 !mb-[24px] fade-in-up sm:!mb-[4px]">
      小迈简介
    </p>
    <p class="w-1440 section-subTitle mb-[48px] px-88 fade-in-up sm:mb-[16px]">
      经过十年的发展，小迈已经成为国内APP开发孵化运营的领导者
    </p>
    <div class="summary sm:hidden">
      <div class="w-1440 summary-content fade-in-up" />
    </div>
    <div class="w-1440 hidden sm:block pl-88">
      <div class="flex mb-[8px] pt-[8px]">
        <div class="line-container">
          <div class="point">
            <div class="point-circle"></div>
          </div>
          <div class="line"></div>
        </div>
        <div class="relative top-[-8px] fade-in-up">
          <div class="title fade-in-up">国内领先的开发者</div>
          <div
            v-for="item in summary"
            :key="item.years"
            class="content mb-[24px]"
          >
            <div class="sub-title">
              <span class="mr-[16px]">{{ item.years }}</span
              ><span>{{ item.subTitle }}</span>
            </div>
            <ul class="content-list">
              <li
                v-for="content in item.content"
                :key="content"
                class="mb-[8px] flex"
              >
                <div class="mr-[8px]">·</div>
                <div>
                  {{ content }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="line-container">
          <div class="point">
            <div class="point-circle"></div>
          </div>
          <div class="line"></div>
        </div>
        <div class="relative top-[-8px] fade-in-up">
          <div class="title">国内领先的开发者孵化运营平台</div>
          <div
            v-for="item in summary2"
            :key="item.years"
            class="content mb-[24px]"
          >
            <div class="sub-title">
              <span class="mr-[16px]">{{ item.years }}</span
              ><span>{{ item.subTitle }}</span>
            </div>
            <ul class="content-list">
              <li
                v-for="content in item.content"
                :key="content"
                class="mb-[8px] flex"
              >
                <div class="mr-[8px]">·</div>
                <div>
                  {{ content }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Summary",
  data: () => ({
    summary: [
      {
        years: "2015",
        subTitle: "启程",
        content: ["10+人的初创团队", "获得天使轮融资"],
      },
      {
        years: "2017",
        subTitle: "锋芒",
        content: [
          "获亿级A轮融资企业估值过亿美金",
          "车主无忧产品累计服务用户破千万 最高月活用户近200万",
          "同年，获高新技术企业证书",
        ],
      },
      {
        years: "2018",
        subTitle: "绽放",
        content: ["CEO 荣获广州市天河区创业领军人才称号"],
      },
      {
        years: "2019",
        subTitle: "深耕",
        content: [
          "被评为广州市天河区十大创业领军企业",
          "认定为国家级高新技术企业",
          "用户规模累计破亿",
        ],
      },
    ],
    summary2: [
      {
        years: "2020",
        subTitle: "蓄势",
        content: [
          "入选 2020 年广东省专精特新业名单",
          "用户规模累计破亿",
          "荣获广州“独角兽”创新企业",
        ],
      },
      {
        years: "2022",
        subTitle: "力拓",
        content: ["2022年“未来独角兽”创新企业", "2022年广州“高精尖”企业"],
      },
      {
        years: "2023",
        subTitle: "精研",
        content: ["2023年“高新技术企业”", "2023互联网企业20强"],
      },
      {
        years: "2024",
        subTitle: "锐进",
        content: ["2024年广州高科技高成长20强", "2024年独角兽创新企业-种子独角兽"],
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
